<template>
    <my-layout class="monitor_main">
        <template #body>
            <div class="body">
                <div class="tip_group">
                    <div class="time_tip">
                        <span :class="timeIndex==='1'?'select':''" @click="changeTime('1')">今天</span>
                        <span :class="timeIndex==='2'?'select':''" @click="changeTime('2')">近7天</span>
                        <span :class="timeIndex==='3'?'select':''" @click="changeTime('3')">近30天</span>
                    </div>
                    <div class="type_tip">
                        <van-tabs v-model="searchType" color="#1f81f8" line-width="70px" @change="changeSearchType">
                            <van-tab name="1">
                                <template #title>
                                    <span>竞品动态 </span>
                                    <span class="count">{{defaultData.company_nums}}</span>
                                </template>
                            </van-tab>
                            <van-tab name="2">
                                <template #title>
                                    <span>关键词动态 </span>
                                    <span class="count">{{defaultData.words_nums}}</span>
                                </template>
                            </van-tab>
                        </van-tabs>
                    </div>
                    <div class="info">
                        <div class="data_cnt" v-if="searchType == '1'">{{defaultData.monitor_company_num}}家竞品公司发生<span class="red">{{defaultData.company_nums}}</span>条推广动态</div>
                        <div class="data_cnt" v-else>{{defaultData.monitor_word_num}}个关键词新增<span class="red">{{defaultData.words_nums}}</span>条推广动态</div>
                        <div class="classify" v-if="searchType == '1'">
                            <div class="classify_item" :class="typeIndex == null?'selected':''" @click="changeType(null, false)">
                                <span>全部</span>
                                <span>动态</span>
                             </div>
                            <div class="classify_item" :class="`${typeIndex == 1?'selected':''} ${defaultData.new_words_nums == 0?'no_data':''}`" @click="changeType(1, defaultData.new_words_nums == 0)">
                                <span>新增关键词</span>
                                <span class="cnt">{{defaultData.new_words_nums}}</span>
                            </div>
                            <div class="classify_item" :class="`${typeIndex == 4?'selected':''} ${defaultData.new_landpage_nums == 0?'no_data':''}`" @click="changeType(4, defaultData.new_landpage_nums == 0)">
                                <span>新增落地页</span>
                                <span class="cnt">{{defaultData.new_landpage_nums}}</span>
                            </div>
                            <div class="classify_item" :class="`${typeIndex == 2?'selected':''} ${defaultData.new_platform_nums == 0?'no_data':''}`" @click="changeType(2, defaultData.new_platform_nums == 0)">
                                <span>新增平台</span>
                                <span class="cnt">{{defaultData.new_platform_nums}}</span>
                            </div>
                            <div class="classify_item" :class="`${typeIndex == 3?'selected':''} ${defaultData.new_domain_nums == 0?'no_data':''}`" @click="changeType(3, defaultData.new_domain_nums == 0)">
                                <span>新增域名</span>
                                <span class="cnt">{{defaultData.new_domain_nums}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="list" v-if="(searchType=='1' && companyDataList.length > 0) || (searchType=='2' && worldDataList.length > 0)">
                    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                        <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="onLoad">
                        <div class="list_item" v-for="(item, i) in searchType=='1'?companyDataList:worldDataList" :key="i">
                            <div class="list_item_header">
                                <span class="key_word">
                                    <span class="left">
                                        <span v-if="item.platform=='1'" class="platform_item">
                                            <img class="first" src="@/assets/img/companyDetails/baidu_search.png" alt="">
                                            <img src="../../assets/img/h5/pc.png" height="29" width="25"/>
                                        </span>
                                        <span v-if="item.platform=='2'" class="platform_item">
                                            <img class="first" src="@/assets/img/companyDetails/baidu_mobile.png" alt="">
                                            <img src="../../assets/img/h5/mobile.png" height="27" width="21"/>
                                        </span>
                                        <span v-if="item.platform=='3'" class="platform_item">
                                            <img class="first" src="@/assets/img/companyDetails/360_search.png" alt="">
                                            <img src="../../assets/img/h5/pc.png" height="29" width="25"/>
                                        </span>
                                        <span v-if="item.platform=='4'" class="platform_item">
                                            <img class="first" src="@/assets/img/companyDetails/360_mobile.png" alt="">
                                            <img src="../../assets/img/h5/mobile.png" height="27" width="21"/>
                                        </span>
                                        <span v-if="item.platform=='5'" class="platform_item">
                                            <img class="first" src="@/assets/img/companyDetails/sougou_search.png" alt="">
                                            <img src="../../assets/img/h5/pc.png" height="29" width="25"/>
                                        </span>
                                        <span v-if="item.platform=='6'" class="platform_item">
                                            <img class="first" src="@/assets/img/companyDetails/sougou_mobile.png" alt="">
                                            <img src="../../assets/img/h5/mobile.png" height="27" width="21"/>
                                        </span>
                                        <span v-if="item.platform=='7'" class="platform_item">
                                            <img src="@/assets/img/companyDetails/shenma_search.png" alt="">
                                        </span>
                                    </span>
                                    <span class="right">{{item.ad_word}}</span>
                                    <img class="search" src="../../assets/img/h5/search_h5.png"/>
                                </span>
                                <div class="header_right">
                                    <span v-if="item.type == '1'">{{searchType=='1'?'新词':'新公司'}}</span>
                                    <span v-if="item.type == '2'">新平台</span>
                                    <span v-if="item.type == '3'">新域名</span>
                                    <span v-if="item.type == '4'">新落地页</span>
                                </div>
                            </div>
                            <a :href="'http://'+item.domain" class="title" v-html="item.em_title"></a>
                            <div class="content">{{item.content}}</div>
                            <div class="date">
                                <span style="margin-right: 10px">{{item.domain}}</span>
                                <span>{{item.ctime}}</span>
                                <span style="margin-left: 10px; color: #7dacfa;">广告</span>
                            </div>
                            <div class="bottom_company">
                                <img src="../../assets/img/h5/company_icon.png"/>
                                <span>{{item.company_name}}</span>
                            </div>
                        </div>
                    </van-list>
                    </van-pull-refresh>
                 </div>
                <van-empty class="custom-image" v-else>
                    <template #image>
                        <img src="../../assets/img/h5/no_data.png"/>
                    </template>
                    <template #description>
                        <div>暂无动态</div>
                    </template>
                </van-empty>
                <div type="default" class="backTop" @click="backTop" v-show="flag_scroll">
                </div>
            </div>
        </template>
    </my-layout>
</template>

<script>
  import * as api from "@/api/monitor"
  import { Empty, Tab, Tabs, List, PullRefresh, Button, Icon  } from 'vant';
  export default {
    name: "monitor",
    components: {
      [Empty.name]: Empty,
      [Tab.name]: Tab,
      [Tabs.name]: Tabs,
      [List.name]: List,
      [PullRefresh.name]: PullRefresh,
      [Button.name]: Button,
      [Icon.name]: Icon,
    },
    data () {
      return {
        defaultData: {},
        timeIndex: '2',
        searchType: '1',
        typeIndex: null,
        getMonitorCompanyParams: {
          cat: '1',
          sort: 'desc',
          read: '',
          page : 1,
          limit: '5',
        },
        companyData: {},
        companyDataList: [],
        companyDataCnt: 0,
        getMonitorWorldParams: {
          cat: '2',
          sort: 'desc',
          read: '',
          page : 1,
          limit: '5',
        },
        worldData: {},
        worldDataList: [],
        worldDataCnt: 0,
        loading: true,
        finished: false,
        refreshing: false,
        flag_scroll: false,
      }
    },
    mounted() {
      this.getMonitorWorld();
    },
    computed: {
      timeSelect() {
        var b_time,e_time;
        switch (this.timeIndex) {
          case '1':
            b_time = this.$C.getBeforeDay(0);
            e_time = this.$C.getBeforeDay(0);
            break;
          case '2':
            b_time = this.$C.getBeforeDay(7);
            e_time = this.$C.getBeforeDay(0);
            break;
          case '3':
            b_time = this.$C.getBeforeDay(30);
            e_time = this.$C.getBeforeDay(0);
            break;
        }
        return {b_time, e_time}
      }
    },
    created() {
      this.onLoad();
      this.getDefaultData()
      window.addEventListener('scroll', this.handleScroll, true)
    },
    methods: {
      onLoad() {
        if(this.searchType=='1') {
          this.getMonitorCompany((data) => {
            this.getMonitorCompanyParams.page++;
            this.companyDataList = [...this.companyDataList,...data.rows]
            if(this.companyDataList.length >= data.cnt) {
              this.finished = true;
            }
            this.loading = false;
            this.refreshing = false;
          });
        } else  {
          this.getMonitorWorld((data) => {
            this.getMonitorWorldParams.page++;
            this.worldDataList = [...this.worldDataList,...data.rows]
            if(this.worldDataList.length >= data.cnt) {
              this.finished = true;
            }
            this.loading = false;
            this.refreshing = false;
          });
        }
      },
      onRefresh() {
        this.finished = false;
        this.loading = true;
        this.searchType=='1'?this.getMonitorCompanyParams.page = 1:this.getMonitorWorldParams.page = 1
        this.getDefaultData()
        this.onLoad();
      },
      changeType(index, noData) {
        if (noData) {
          return false
        }
        if(this.typeIndex != index) {
          this.typeIndex = index;
          this.finished = false;
          if(this.searchType=='1') {
            this.getMonitorCompanyParams.page = 1;
            this.companyDataList = [];
            this.getDefaultData()
            this.onLoad()
          } else {
            this.getMonitorWorldParams.page = 1;
            this.worldDataList = [];
            this.getDefaultData()
            this.onLoad()
          }
        }
      },
      backTop(){
        document.getElementsByClassName('layout_body')[0].scrollTop = 0
      },
      handleScroll(env){
        let scrollTop = document.getElementsByClassName('layout_body')[0].scrollTop
        if(scrollTop > 1000){
          this.flag_scroll = true
        }else {
          this.flag_scroll = false
        }
      },
      changeTime(type) {
        if(this.timeIndex != type) {
          this.timeIndex = type;
          this.finished = false;
          if(this.searchType=='1') {
            this.getMonitorCompanyParams.page = 1;
            this.companyDataList = [];
            this.getDefaultData()
            this.onLoad()
          } else {
            this.getMonitorWorldParams.page = 1;
            this.worldDataList = [];
            this.getDefaultData()
            this.onLoad()
          }
        }
      },
      changeSearchType(name) {
        this.reset();
        this.companyDataList = [];
        this.worldDataList = [];
        this.typeIndex =  null
        this.finished = false;
        this.getDefaultData()
        this.onLoad()
      },
      reset() {
        Object.assign(this.$data.getMonitorCompanyParams, this.$options.data().getMonitorCompanyParams);
        Object.assign(this.$data.getMonitorWorldParams, this.$options.data().getMonitorWorldParams);
      },
      getMonitorCompany(fn) {
        let params = {
          ...this.getMonitorCompanyParams,
          ...this.timeSelect,
          type: this.typeIndex
        };
        api.get_monitor_list(params).then(res =>{
            if(res.data.result_code==0) {
              this.companyDataCnt = res.data.data.cnt;
              fn && fn(res.data.data)
            }
        })
      },
      getMonitorWorld(fn) {
        let params = {
          ...this.getMonitorWorldParams,
          ...this.timeSelect,
          type: this.typeIndex
        };
        api.get_monitor_list(params).then(res =>{
          if(res.data.result_code==0) {
            this.worldDataCnt = res.data.data.cnt;
            fn && fn(res.data.data)
          }
        })
      },
      getDefaultData() {
        let params = {
          cat: this.searchType,
          b_time: this.timeSelect.b_time,
          e_time: this.timeSelect.e_time,
        }
        let p = new Promise((resolve, reject) => {
          api.get_monitor_report(params).then(res => {
            if(res.data.result_code == 0) {
              this.defaultData = res.data.data
              resolve(res.data.data);
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        })
        return p;
      }
    }
  }
</script>

<style scoped lang="scss">
    .monitor_main {
        .red {
            color: #ef3819;
        }
        .blue {
            color: #1f81f8;
        }
        .header {
            width: 100%;
            height: 170px;
            background-color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            .title {
                font-size: 54px;
                color: #333333;
            }
            img {
                width: 86px;
                height: 73px;
                position: absolute;
                right: 45px;
            }
        }
        .body {
            font-size: 42px;
            .tip_group {
                background-color: #ffffff;
                padding-top: 25px;
                border-bottom: 1px solid #eeeeee;
                .time_tip {
                    width: 100%;
                    height: 105px;
                    display: flex;
                    justify-content: space-around;
                    color: #333333;
                    font-size: 42px;
                    span {
                        width: 220px;
                        height: 80px;
                        text-align: center;
                        line-height: 80px;
                    }
                    .select {
                        color: #1f81f8;
                        border-radius: 12px;
                        border: solid 2px #e4e4e4;
                        box-sizing: border-box;
                    }
                }
                .type_tip {
                  border-bottom: 1px solid #eeeeee;
                }
                .info {
                    padding: 10px 40px;
                    .data_cnt {
                        margin-top: 42px;
                        margin-bottom: 45px;
                        font-size: 42px;
                        color: #999999;
                    }
                    .classify {
                        padding: 14px;
                        display: flex;
                        justify-content: space-around;
                        background-color: #f8fafc;
                        border-radius: 12px;
                        border: solid 2px #ebeff2;
                        margin-bottom: 28px;
                        font-size: 42px;
                        .classify_item {
                            padding: 19px 25px;
                            height: 97px;
                            display: inline-flex;
                            justify-content: space-between;
                            flex-direction: column;
                            align-items: center;
                            border-radius: 9px;
                            transition:  background-color 0.3s;
                            white-space: nowrap;
                            font-size: 36px;
                            .cnt {
                                color: #1f81f8;
                                font-size: 42px;
                            }
                        }
                        .selected {
                            background-color: #1f81f8;
                            color: #FFFFFF;
                            .cnt {
                                color: #FFFFFF;
                            }
                        }
                        .no_data {
                            color: #999999;
                            .cnt {
                                color: #999999;
                            }
                        }
                    }
                }
            }
            .list {
                .list_item {
                    padding: 44px 42px;
                    margin: 24px 0;
                    background-color: #ffffff;
                    .list_item_header {
                        display: flex;
                        margin-bottom: 37px;
                        align-items: center;
                        justify-content: space-between;
                        .key_word {
                            max-width: 75%;
                            background-color: #ffffff;
                            border: solid 2px #e4e4e4;
                            display: inline-flex;
                            align-items: center;
                            /*padding-top: 15px;*/
                            /*padding-bottom: 15px;*/
                            padding-right: 36px;
                            border-radius: 40px;
                            font-size: 42px;
                            overflow-y: hidden;
                            .left {
                                height: 74px;
                                padding-left: 35px;
                                padding-right: 25px;
                                background-color: #f5f6f7;
                                line-height: 74px;
                                border-right: 1px solid #e4e4e4;
                                .platform_item {
                                    white-space: nowrap;
                                    .first {
                                        margin-right: 9px;
                                    }
                                    img {
                                        width: 35px;
                                        height: 35px;
                                    }
                                }
                            }
                            .right {
                                margin-left: 35px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                            .search {
                                width: 36px;
                                height: 36px;
                                margin-left: 24px;
                            }
                        }
                        .header_right {
                            border-radius: 60px;
                            padding: 17px 27px;
                            font-size: 42px;
                            color: #1f81f8;
                            background-color: rgba(31, 129, 248, 0.1);
                        }
                    }
                    .title {
                        font-size: 53px;
                        color: #333333;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        line-height: 66px;
                        overflow: hidden;

                    }
                    .content {
                        font-size: 42px;
                        color: #555555;
                        line-height: 66px;
                        margin: 37px 0;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        overflow: hidden;
                    }
                    .date {
                        padding-bottom: 30px;
                        border-bottom: 1px solid #eeeeee;
                        color: #999999;
                        margin-bottom: 30px;
                        font-size: 42px;
                        a {
                            color: #999999;
                            margin-right: 50px;
                        }
                    }
                    .bottom_company {
                        font-size: 42px;
                        margin-top: 26px;
                        display: flex;
                        align-items: center;
                        color: #555555;
                        img {
                            width: 42px;
                            height: 37px;
                            margin-right: 18px;
                        }
                    }
                }
            }
            .backTop {
                width: 160px;
                height: 160px;
                position: fixed;
                bottom: 100px;
                right: 35px;
                background: url("../../assets/img/h5/to_top.png");
                background-size: cover;
            }
        }
    }
</style>

<style lang="scss">
    .monitor_main {
        .body {
            em {
                color: #f1604b;
            }
            .custom-image {
                .van-empty__image {
                    width: 533px;
                    height: 410px;
                    margin-top: 366px;
                }
                .van-empty__description {
                    text-align: center;
                    font-size: 42px;
                    color: #999999;
                    line-height: 74px;
                }
            }
            .van-tabs__wrap {
                .van-tab {
                    font-size: 48px;
                    color: #333333;
                    .count {
                        font-size: 42px;
                        color: #999999;
                    }
                }
                .van-tab--active {
                    font-weight: bold;
                    .count {
                        font-weight: normal;
                        color: #ef3819;
                    }
                }
            }
        }
    }
</style>
